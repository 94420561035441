@font-face {
  font-family: 'BwHelderDEMO-W1Bold';
  src: local('BwHelderDEMO-W1Bold'),
    url(./fonts/BwHelderDEMO-W1Bold.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W1ExtraBold';
  src: local('BwHelderDEMO-W1ExtraBold'),
    url(./fonts/BwHelderDEMO-W1ExtraBold.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W1Light';
  src: local('BwHelderDEMO-W1Light'),
    url(./fonts/BwHelderDEMO-W1Light.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W1Medium';
  src: local('BwHelderDEMO-W1Medium'),
    url(./fonts/BwHelderDEMO-W1Medium.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W1Regular';
  src: local('BwHelderDEMO-W1Regular'),
    url(./fonts/BwHelderDEMO-W1Regular.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W1Thin';
  src: local('BwHelderDEMO-W1Thin'),
    url(./fonts/BwHelderDEMO-W1Thin.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W2Bold';
  src: local('BwHelderDEMO-W2Bold'),
    url(./fonts/BwHelderDEMO-W2Bold.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W2ExtraBold';
  src: local('BwHelderDEMO-W2ExtraBold'),
    url(./fonts/BwHelderDEMO-W2ExtraBold.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W2Light';
  src: local('BwHelderDEMO-W2Light'),
    url(./fonts/BwHelderDEMO-W2Light.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W2Medium';
  src: local('BwHelderDEMO-W2Medium'),
    url(./fonts/BwHelderDEMO-W2Medium.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W2Regular';
  src: local('BwHelderDEMO-W2Regular'),
    url(./fonts/BwHelderDEMO-W2Regular.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W2Thin';
  src: local('BwHelderDEMO-W2Thin'),
    url(./fonts/BwHelderDEMO-W2Thin.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W3Bold';
  src: local('BwHelderDEMO-W3Bold'),
    url(./fonts/BwHelderDEMO-W3Bold.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W3ExtraBold';
  src: local('BwHelderDEMO-W3ExtraBold'),
    url(./fonts/BwHelderDEMO-W3ExtraBold.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W3Light';
  src: local('BwHelderDEMO-W3Light'),
    url(./fonts/BwHelderDEMO-W3Light.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W3Medium';
  src: local('BwHelderDEMO-W3Medium'),
    url(./fonts/BwHelderDEMO-W3Medium.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W3Regular';
  src: local('BwHelderDEMO-W3Regular'),
    url(./fonts/BwHelderDEMO-W3Regular.otf) format('otf');
}
@font-face {
  font-family: 'BwHelderDEMO-W3Thin';
  src: local('BwHelderDEMO-W3Thin'),
    url(./fonts/BwHelderDEMO-W3Thin.otf) format('otf');
}

body {
  margin: 0;
  font-family: 'BwHelderDEMO-W1Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1280px;
}

#root {
  /* min-width: 1280px; */
}

div {
  display: flex;
}

.App {
  flex-direction: column;
  flex: 1;
  width: 1280px;
  min-width: 1280px;
}

.ativo {
  width: 200px;
  height: 540px;
}

.container {
  width: 100%;
  justify-content: center;
}

.content {
  min-width: 1280px;
}

.header {
  width: 100%;
  height: 203px;
  background-image: url('./imgs/bg_header2.png');

  background-size: cover;
}

.logo {
  width: 250px;
  height: 90px;
}

.menu {
  font-family: 'BwHelderDEMO-W2Bold';
  font-size: 16;
  margin-top: 32px;
  color: #fbe9e2;
}

.menu > div {
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
}

.menu > div:hover {
  color: #d0bfb9;
}

.banner {
  width: 100%;
  height: 517px;
  margin-bottom: 0px;
  background-image: url('./imgs/banner2.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.banner-info {
  margin-top: 250px;
  color: #760b32;
  font-family: 'BwHelderDEMO-W2Medium';
  flex-direction: column;
  margin-left: 40px;
  font-size: 20px;
}

.banner-title {
  font-size: 32px;
}

.banner-button {
  color: #fff;
  background-color: #760b32;
  cursor: pointer;
  font-size: 18px;
  border-radius: 1px;
  padding: 2px 5px;
  margin-top: 1px;
}

.about-us {
  margin-top: -32px;
  height: 650px;
  z-index: 999;
  min-width: 1280px;
}

.about-box {
  background-color: #fff;
  margin-left: 190px;
  width: 435px;
  height: 385px;
  padding: 40px 30px;
  border: 1px solid rgba(95, 50, 46, 0.4);
  box-shadow: 0px 3px 4px 2px rgba(95, 50, 46, 0.4);
  z-index: 9999;
  color: #753c38;
  flex-direction: column;
  text-align: left;
  font-size: 23px;
}

.about-title {
  font-family: 'BwHelderDEMO-W2Medium';
  color: #60322d;
  font-size: 50px;
}

.about-subtitle {
  font-family: 'BwHelderDEMO-W1Medium';
  color: #a3837b;
  font-size: 24px;
  margin-top: 5px;
}

.about-hr {
  border: 1px solid rgba(95, 50, 46, 0.3);
  margin-left: -30px;
  margin-top: 7px;
  margin-bottom: 15px;
}

.about-button {
  border: 1px solid rgba(95, 50, 46, 0.3);
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 20px;
  font-weight: bold;
}

.services-box {
  font-family: 'BwHelderDEMO-W2Medium';
  margin-left: 116px;
  margin-right: 116px;
  background-color: #f6e8e1;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 45px 75px;
  padding-bottom: 700px;
  color: #60322d;
  font-size: 46px;
}

.service {
  font-family: 'BwHelderDEMO-W3Thin';
  border: 1px solid rgba(95, 50, 46, 0.3);
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 40px;
}

.service-button {
  font-family: 'BwHelderDEMO-W2Medium';
  color: #f6e8e1;
  background-color: #5f322e;
  padding: 12px 16px;
  border-radius: 8px;
  margin-top: 50px;
  font-size: 23px;
  cursor: pointer;
}

.clients {
  z-index: 9999;
  width: 100%;
  height: 585px;
  background-image: url('./imgs/bg_clients.png');
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
}

.client-title {
  font-family: 'BwHelderDEMO-W2Medium';
  justify-content: center;
  color: #ffff;
  font-size: 50px;
  margin-top: 120px;
}

.depos {
  flex-direction: row;
  margin-top: 60px;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
}

.depo {
  font-family: 'BwHelderDEMO-W3Regular';
  width: 445px;
  background-color: #fff;
  flex-direction: column;
  color: #5f322e;
  padding: 30px 20px;
  text-align: justify;
  padding-bottom: 5px;
}

.client-name {
  font-family: 'BwHelderDEMO-W2Medium';
  flex-direction: column;
  align-items: flex-end;
  margin-right: 30px;
  font-size: 28px;
  margin-top: 20px;
}

.client-hr {
  width: 444px;
  border: 1px solid rgba(95, 50, 46, 0.3);
  margin-left: -30px;
  margin-top: 7px;
  margin-bottom: 15px;
}

.people-box {
  font-family: 'BwHelderDEMO-W2Medium';
  margin-left: 116px;
  margin-right: 116px;
  background-color: #f6e8e1;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 90px;
  height: 260px;
  color: #60322d;
  font-size: 44px;
  margin-top: -10px;
}

.doctors-box {
  margin-left: 170px;
  margin-right: 170px;
  background-color: #60322d;
  flex: 1;
  flex-direction: row;
  height: 482px;
  margin-top: -84px;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 60px;
  z-index: 2;
}

.doctor {
  width: 358px;
  height: 370px;
  flex-direction: column;
}
.doctor-photo {
  width: 360px;
  height: 205px;
}

.doctor-name {
  margin-top: 28px;
  font-family: 'BwHelderDEMO-W2Medium';
  color: #f6e8e1;
  font-size: 30px;
}

.doctor-hr {
  width: 200px;
  border: 1px solid rgba(246, 232, 225, 0.3);
  margin-top: 10px;
  margin-bottom: 15px;
}

.doctor-description {
  font-family: 'BwHelderDEMO-W2Medium';
  color: #f6e8e1;
  font-size: 14px;
  text-align: justify;
}

.contact {
  width: 100%;
  background-image: url('./imgs/contact.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 872px;
  flex-direction: column;
  align-items: center;
  margin-top: -90px;
}

.contact-box {
  width: 730px;
  height: 360px;
  flex-direction: row;
  margin-top: 280px;
  background-color: #fff;
  padding: 40px;
}

.contact-photo {
  width: 270px;
  height: 370px;
}

.contact-photo > img {
  width: 100%;
  height: 100%;
}

.contact-form {
  flex-direction: column;
}

.contact-title {
  font-family: 'BwHelderDEMO-W2Medium';
  text-align: left;
  margin-left: 20px;
  color: #5f322e;
  font-size: 36px;
}

.contact-hr {
  width: 380px;
  border: 2px solid rgba(95, 50, 46, 0.3);
  margin-top: 10px;
  margin-bottom: 15px;
}

input {
  margin-top: 40px;
  margin-left: 15px;
  flex: 1;
  border: none;
  border-bottom: 2px solid rgba(95, 50, 46, 0.3);
  font-size: 16px;
}

textarea {
  resize: none;
  border: none;
  margin-left: 15px;
  width: 320px;
  border-bottom: 2px solid rgba(95, 50, 46, 0.3);
  margin-top: 60px;
  font-size: 16px;
}
.send-button {
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  margin-top: 70px;
  margin-left: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 60px;
  height: 20px;
  background-color: #5f322e;
  color: #f6e8e1;
  font-family: 'BwHelderDEMO-W2Medium';
  font-size: 10px;
}
.footer {
  width: 100%;
}
.footer-logo {
  width: 514px;
  height: 335px;
  background-color: #792f3b;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer-logo img {
  margin-top: 110px;
  width: 250px;
  height: 90px;
}

.copyright {
  color: #a2837c;
  align-self: flex-start;
  margin-top: auto;
  font-size: 9px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.footer-info {
  font-family: 'BwHelderDEMO-W2Medium';
  width: 100%;
  flex: 1;
  background-color: #a2837c;
  flex-direction: column;
  padding: 35px;
  padding-bottom: 10px;
  text-align: left;
  color: #fff;
}
.footer-title {
  margin-top: 28px;
}
.socials {
  margin-top: 30px;
  height: 40px;
}
.socials > div {
  margin-right: 10px;
  width: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.socials > a {
  display: block;
  margin-right: 5px;
  width: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.socials > div > img {
  height: 100%;
}
.socials > a > img {
  height: 100%;
}
